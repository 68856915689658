import * as React from "react"


const NotFoundPage = () => (
  <>
    <h1>404: Not Found</h1>
  </>
)

export default NotFoundPage
